import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Settings } from '../models/Setting';
import { settings } from '../settings/config';

@Injectable({
	providedIn: 'root'
})

export class SettingsService {
    constructor(private http: HttpClient) {

    }

    GetSetting():Observable<Settings> {  
        let setting: settings = new settings();     
        return this.http.get<Settings>(setting.APIurl + 'api/settings/getsettings');
    }

   
}