import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService) {
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
  }

  get filterbyCategory() {
    let categories: string[] = [];
    this.products.map(product => product.type).forEach(element => {
      element.split(",").forEach(item => {
        if (categories.filter(x => x == item).length == 0) {
          categories.push(item);
        }
      })
    });
    const category = [...new Set(categories)]
    return category
  }

}
