<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<!-- <section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form"
							action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
							target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
									placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section> -->
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<!-- <h4>about</h4> -->
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<!-- <img [src]="themeLogo" alt="logo" style="max-width: 179px;"> -->
							<a href="https://eticaretinolsun.com" target="_blank"><img src="{{config.APIurl}}userfiles/bahas_logo.png" alt="logo" style="max-width: 220px;"></a>
						</div>
						<p>Alışverişleriniz <a  href="https://eticaretinolsun.com" target="_blank">Eticaretinolsun.com</a>  Alt Yapısı ve Güvenlik Sistemleri ile Desteklenmekte ve
							Takip Edilmektedir.</p>
							<div class="footer-social">
								<ul>
									<li *ngIf="this.settings.Facebook!=''">
										<a [href]="this.settings.Facebook" target="_blank"><i class="fa fa-facebook"
												aria-hidden="true"></i></a>
									</li>
									<li *ngIf="this.settings.GooglePlus!=''">
										<a [href]="this.settings.GooglePlus" target="_blank"><i class="fa fa-google-plus"
												aria-hidden="true"></i></a>
									</li>
									<li *ngIf="this.settings.Twitter!=''">
										<a [href]="this.settings.Twitter" target="_blank"><i class="fa fa-twitter"
												aria-hidden="true"></i></a>
									</li>
									<li *ngIf="this.settings.Instagram!=''">
										<a [href]="this.settings.Instagram" target="_blank"><i class="fa fa-instagram"
												aria-hidden="true"></i></a>
									</li>
									<li *ngIf="this.settings.Linkedin!=''">
										<a [href]="this.settings.Linkedin" target="_blank"><i class="fa fa-rss"
												aria-hidden="true"></i></a>
									</li>
								</ul>
							</div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Kategoriler</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li *ngFor="let item of categories"><a
										href="/kategori/urunler?category={{item.Name}}">{{item.Name}}</a></li>

							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Kurumsal</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li><a href="/pages/content?id={{this.settings.AboutUsContentId}}">Hakkımızda</a></li>
								<li><a href="/pages/content?id=5097">Uzaktan Satış ve Iade Sözleşmesi</a></li>
								<li><a href="/pages/content?id=5100">Gizlilik Sözleşmesi</a></li>
								<li><a href="/pages/content?id=5098">Üyelik Sözleşmesi</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>İletişim bilgileri</h4>
						</div>
						<div class="footer-contant">
							<ul *ngIf="this.settings" class="contact-list">
								<li><i class="fa fa-map-marker"></i>{{this.settings.Name}}</li>
								<li><i class="fa fa-phone"></i>{{this.settings.MobilePhone}}</li>
								<li><i class="fa fa-envelope-o"></i><a
										style="font-size: 12px !important;text-transform: lowercase;">{{this.settings.ContactEmail}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Eticaretinolsun.com
							Bilişim Hizmetleri</p>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<!-- <li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li> -->
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

<!--footer section end -->