import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Entity } from '../shared/models/Entity';
import { Settings } from '../shared/models/Setting';
import { EntityService } from '../shared/services/entity.service';
import { SettingsService } from '../shared/services/settings.service';
import { settings } from '../shared/settings/config';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  public url: any;
  public settings: Settings;
  public environment: any;

  constructor(private router: Router, public settingService: SettingsService, public entityService: EntityService,) {

    this.environment = environment;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

    this.settingService.GetSetting().subscribe((response: Settings) => {
      let appSetting: settings = new settings();
      this.settings = response;
      this.settings.HeaderLogo = appSetting.APIurl + '/userfiles/' + window.location.hostname + '/image/' + this.settings.HeaderLogo;
      this.settings.FooterLogo = appSetting.APIurl + '/userfiles/' + window.location.hostname + '/image/' + this.settings.FooterLogo;
    });

  }

  ngOnInit(): void {
  }

}



