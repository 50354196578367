import { Entity } from 'src/app/shared/models/Entity';
import { HttpClient } from '@angular/common/http';
import { settings } from "src/app/shared/settings/config";
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EntityValue } from '../models/EntityValue';

@Injectable({
    providedIn: 'root',
})

export class EntityService {
    constructor(private http: HttpClient) {
    }

    GetEntityListByEntitySetID(entitySetID: Number): Observable<any> {
        let setting: settings = new settings();
        return of(this.http.get(setting.APIurl + 'api/Entity/EntityListBySetID?EntitySetID=' + entitySetID));
    }

    GetEntityListByEntitySetIDresult(entitySetID: Number): Observable<Entity[]> {
        let setting: settings = new settings();
        return this.http.get<Entity[]>(setting.APIurl + 'api/Entity/EntityListBySetID?EntitySetID=' + entitySetID);
    }

    GetEntityById(entityId: Number): Observable<any> {
        let setting: settings = new settings();
        return of(this.http.get(setting.APIurl + 'api/Entity/GetEntityById?entityId=' + entityId));
    }

    GetEntityByIdPublic(entityId: Number): Observable<any> {
        let setting: settings = new settings();
        return of(this.http.get(setting.APIurl + 'api/Entity/GetEntityByIdPublic?entityId=' + entityId));
    }

    GetEntitySetByEntitySetID(entitySetID: Number): Observable<any> {
        let setting: settings = new settings();
        return of(this.http.get(setting.APIurl + 'api/Entity/GetEntitySetById?EntitySetID=' + entitySetID));
    }

    GetAttributeValue(entity: Entity, attributeID: Number): string {
        if (entity.EntityValues === undefined) return "";
        return entity.EntityValues.find(x => x.EntityAttributeID == attributeID).Value;
    }

    GetAttribute(entity: Entity, attributeID: Number): EntityValue {
        return entity.EntityValues.find(x => x.EntityAttributeID == attributeID);
    }
}