import { environment } from "src/environments/environment";

export class settings {
    constructor() {
    }
    public get APIurl(): string {
        return environment.APIurl;
    }

    public get WebAPIurl(): string {
        return environment.WebAPIurl;
    }
    public get DnsSafeUrl(): string {
        let host = window.location.host;
        if (host == "localhost:4200") {
            host = "localhost";
        }
        return host; 
    }

}