import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'src/app/shared/classes/user.types';
import { settings } from '../settings/config';
import { Seller } from '../models/Seller';
import { HttpControllerResponse } from '../models/HttpRequestResponse/HttpControllerResponse';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userUnilevelTreeJSON: ReplaySubject<Seller[]> = new ReplaySubject<Seller[]>(1);
    datepipe: any;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get unilevelTreeJSON$(): Observable<Seller[]> {
        return this._userUnilevelTreeJSON.asObservable();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<Seller> {

        let setting: settings = new settings();
        let sellerId: string;

        // Subscribe to the user service
        this.user$
            .pipe()
            .subscribe((user: User) => {
                sellerId = user.id;
            });

        return this._httpClient.get<Seller>(setting.WebAPIurl + 'seller/FindById', {
            params: {
                id: sellerId
            }
        }).pipe(
            tap((seller) => {
                let resultUser: User = {
                    name: seller.firstName + " " + seller.lastName,
                    email: seller.eMail,
                    id: seller.id.toString(),
                    referrerCode: seller.refCode
                };
                this._user.next(resultUser);
            })
        );
    }


    getUnilevelTree(): Observable<HttpControllerResponse> {
        let setting: settings = new settings();
        let sellerId: string;
        // Subscribe to the user service
        this.user$
            .pipe()
            .subscribe((user: User) => {
                sellerId = user.id;
            });
        return this._httpClient.get<HttpControllerResponse>(setting.APIurl + 'Seller/GenerateUnileveltreeAsync', {
            params: {
                sellerId: sellerId
            }
        }).pipe(
            tap((response: HttpControllerResponse) => {
                this._userUnilevelTreeJSON.next(response.result);
            })
        );
    }

    getRefferenceMemberList(): Observable<HttpControllerResponse> {
        let setting: settings = new settings();
        let sellerId: string;
        // Subscribe to the user service
        this.user$
            .pipe()
            .subscribe((user: User) => {
                sellerId = user.id;
            });
        return this._httpClient.get<HttpControllerResponse>(setting.APIurl + 'Seller/GetUnilevelSellerList', {
            params: {
                sellerId: sellerId
            }
        }).pipe(
            tap((response: HttpControllerResponse) => {
                this._userUnilevelTreeJSON.next(response.result);
            })
        );
    }

    getFirstGroupCareerType(sellerId: number): Observable<HttpControllerResponse> {
        let setting: settings = new settings();
        let date = new Date();
        let dateTimeNow = this.datepipe.transform(date, 'yyyy-MM-dd');
        return this._httpClient.get<HttpControllerResponse>(setting.APIurl + 'Seller/GetFirstGroupCareerType', {
            params: {
                sellerId: sellerId,
                endDate: dateTimeNow
            }
        }
        );
    }

    getEcommerceCredit(sellerId: number): Observable<HttpControllerResponse> {
        let setting: settings = new settings();       
        return this._httpClient.get<HttpControllerResponse>(setting.WebAPIurl + 'MLM/EcommerceCredit', {
            params: {
                sellerId: sellerId
            }
        }
        );
    }

    getDomains(sellerId: number): Observable<HttpControllerResponse> {
        let setting: settings = new settings();
        return this._httpClient.get<HttpControllerResponse>(setting.APIurl + 'Seller/GetDomainsBySellerIdAsync', {
            params: {
                sellerId: sellerId
            }
        }
        );
    }

    getUserBankAccountInfo(sellerId: number): Observable<HttpControllerResponse> {
        let setting: settings = new settings();
        return this._httpClient.get<HttpControllerResponse>(setting.APIurl + 'BankAccount/GetBankAccountBySellerId', {
            params: {
                sellerId: sellerId
            }
        }
        );
    }

    UpsertBankAccountByUserId(sellerId: number, iban: string, accountName: string, bankName: string): Observable<HttpControllerResponse> {
        let setting: settings = new settings();
        return this._httpClient.post<HttpControllerResponse>(setting.APIurl + 'BankAccount/UpsertBankAccountBySellerId', {
            sellerId: sellerId,
            iban: iban,
            accountName: accountName,
            bankName: bankName
        }
        );
    }

    UpsertDomain(sellerId: number, domainName: string): Observable<HttpControllerResponse> {
        let setting: settings = new settings();
        return this._httpClient.get<HttpControllerResponse>(setting.APIurl + 'Seller/UpsertDomainAsync', {
            params: {
                sellerId: sellerId,
                domainName: domainName
            }
        }
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}