import { Component, OnInit } from '@angular/core';
import { Settings } from '../shared/models/Setting';
import { SettingsService } from '../shared/services/settings.service';
import { settings } from '../shared/settings/config';
import { environment } from "src/environments/environment";
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  public settings: Settings;
  public environment: any;

  constructor(public settingService: SettingsService, private metaService: Meta) {
    this.settingService.GetSetting().subscribe((response: Settings) => {
      let appSetting: settings = new settings();
      this.settings = response;
      this.settings.HeaderLogo = appSetting.APIurl + '/userfiles/' + window.location.hostname + '/image/' + this.settings.HeaderLogo;
      this.settings.FooterLogo = appSetting.APIurl + '/userfiles/' + window.location.hostname + '/image/' + this.settings.FooterLogo;
    });
    this.environment = environment;

    

  }

  ngOnInit(): void {
  }

}
