import { Component, OnInit, Input } from '@angular/core';
import { CategoriesEnt } from '../../models/Category';
import { Settings } from '../../models/Setting';
import { CategoryService } from '../../services/category.service';
import { settings } from '../../settings/config';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  @Input() settings: Settings; // Default True


  public today: number = Date.now();
  public config: settings = new settings();
  public categories: CategoriesEnt[];
  constructor(private categoryService: CategoryService) {
    categoryService.categories().subscribe((response: CategoriesEnt[]) => {
      this.categories = response.filter(x => x.MainId == 1 && x.MetaKeywords == "");      
    });
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: any) {
    if (this.settings !== undefined && this.settings.FooterScript !== undefined && this.settings.HeaderScript !== undefined) {
      document.body.appendChild(document.createRange().createContextualFragment(this.settings.FooterScript));
      document.head.appendChild(document.createRange().createContextualFragment(this.settings.HeaderScript));
    }

  }

}


