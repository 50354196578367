import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryService } from './category.service';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {
	constructor(categoryService: CategoryService) {
		categoryService.categories().subscribe((resp: any[]) => {		
			const allCategories = resp;
			resp.filter(x => x.MainId == 1).forEach(element => {
				if (allCategories.filter(x => x.MainId == element.ID).length > 0) {
					this.MENUITEMS.push({
						active: false,
						title: element.Name,
						path: element.MetaKeywords == null || element.MetaKeywords == ""  ? '/kategori/urunler?category=' + encodeURIComponent(element.Name):element.MetaKeywords,
						type: 'sub',//sub, extLink, link
						children: allCategories.filter(x => x.MainId == element.ID)
							.map(x => <Menu>{ 
								active: true,
								title: x.Name,
								path: x.MetaKeywords == null || x.MetaKeywords == ""? '/kategori/urunler?category=' + encodeURIComponent(x.Name):x.MetaKeywords,
								type: 'extLink',//sub, extLink, link
							})
					});
				} else {
					this.MENUITEMS.push({
						active: true,
						title: element.Name,
						path:  element.MetaKeywords == null || element.MetaKeywords == "" ? '/kategori/urunler?category=' + encodeURIComponent(element.Name):element.MetaKeywords,
						type: 'extLink'//sub, extLink, link

					});
				}
			});
		});

	}

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}


	MENUITEMS: Menu[] = [];

	LEFTMENUITEMS: Menu[] = [];



	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
