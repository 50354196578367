import { ChangeDetectionStrategy, Component, Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    //delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
    public router: Router
  ) {
    

    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang(environment.defaultLang);
      translate.addLangs(['en', 'fr']);
    }
  }

  ngOnInit(): void {
    // const gtmTag = {
    //   event: 'page',
    //   pageName: this.router.url
    // };
    // this.gtmService.config.id ='GTM-M2FQZ88';
    // this.gtmService.pushTag(gtmTag); 

  }
}
//GTM-M2FQZ88