import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../../classes/user.types';
import { Settings } from '../../models/Setting';
import { AuthService } from '../../services/auth/auth.service';
import { AuthGuard } from '../../services/auth/guards/auth.guard';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header-four',
  templateUrl: './header-four.component.html',
  styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent implements OnInit {

  @Input() class: string = 'header-2 header-6';
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() siteSettings: Settings; // Default false


  public stick: boolean = false;
  public environment=environment;

  searchKey: string = '';
  _user: User;

  constructor(
    private router: Router,
    private _userService: UserService,
    private authGuard: AuthGuard,
    private authService: AuthService
  ) {

    this.authGuard.Check().subscribe((response: boolean) => {
      if (response) {
        this._userService.user$
          .subscribe((user: User) => {
            this._user = user;
          });
      }
    });



  }

  ngOnInit(): void {
  }

  search(key: string) {

    this.router.navigate(['./shop/search'], { queryParams: { filter: key } });
  }

  btnSignOut_Click(): void {
    this.authService.signOut();
    location.reload();
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
