<!-- header start -->
<header *ngIf="siteSettings" class="sticky" [ngClass]="class">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>{{siteSettings.Name}} {{'welcome' | translate}}</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i> {{siteSettings.MobilePhone}}</li> <!--{{'phone' | translate}}: -->
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right d-none">
          <ul class="header-dropdown">
            <li class="compare" *ngIf="this._user==null" style="padding-right: 25px !important;">
              <a [routerLink]="['/pages/login']"><i class="fa fa-sign-in" aria-hidden="true"></i>{{'sign-in' | translate}}</a>
            </li>
            <li class="mobile-wishlist" *ngIf="this._user==null && environment.isEcommerceActive">
              <a [routerLink]="['/pages/register']"><i class="fa fa-user-plus" aria-hidden="true"></i>{{'sign-up' | translate}}</a>
            </li>
            <li *ngIf="this._user!=null" class="onhover-dropdown mobile-account" style="padding: 15px 25px !important;">
              <i class="fa fa-user" aria-hidden="true"></i> {{'account' | translate}}
              <ul class="onhover-show-div">
                <li>
                  <a data-lng="en">
                    <i class="fa fa-sign-in" aria-hidden="true"></i> {{'account-details' | translate}}
                  </a>
                </li>
                <li>
                  <button type="button" data-lng="es"  (click)="btnSignOut_Click()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i> {{'exit' | translate}}
                  </button>
                </li>
              </ul>
            </li>
            <li *ngIf="this._user!=null" style="padding: 15px 25px !important;">{{this._user.name}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu border-section border-top-0">
          <div class="brand-logo layout2-logo">
            <a [routerLink]="['/anasayfa']">
              <img [src]="siteSettings.HeaderLogo" class="img-fluid" style="max-width: 179px;" alt="logo">
            </a>
          </div>
          <div>
            <form class="form_search" role="form">
              <input id="query search-autocomplete" [(ngModel)]="searchKey" type="search" name="searchKey"
                (keyup.enter)="search(searchKey)" [placeholder]="'searchInputPlaceHolder' | translate"
                class="nav-search nav-search-field" aria-expanded="true">
              <button type="button" name="nav-submit-button" (click)="search(searchKey)" class="btn-search">
                <i class="ti-search"></i>
              </button>
            </form>
          </div>
          <div class="menu-right pull-right">
            <app-settings></app-settings>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row ">
      <div class="col-lg-12">
        <div class="main-nav-center">
          <app-menu></app-menu>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->